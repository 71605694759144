import optionData from '@/assets/js/optionData';
import { fetchCountryList } from '@/api/DDJ/country';
import { getAdvertiserList } from '@/api/DDJ/advertiser/index';
import { fetchEMUTaskPage, handleEMUTaskEdit, fetchEMUTaskDel } from '@/api/DDJ/emu';
export default {
  components: {},
  data() {
    return {
      filter: {},
      options: {
        country: [],
        os: optionData.osOption,
        advertiser: [],
        status: [
          {
            label: '开启',
            value: 1,
          },
          {
            label: '关闭',
            value: 0,
          },
        ],
      },
      list: [
        {
          offerId: '1',
        },
      ],
      pages: {
        pageNum: 1,
        pageSize: 10,
      },
      taskEdit: {},
      pagesTotal: 0,
      isShowPage: false,
      diallog: {
        task: false,
      },
      loading: {
        list: false,
        btn: false,
      },
      rules: {
        price: [
          {
            required: true,
            message: '请输入价格',
            trigger: 'blur',
          },
        ],
        installCount: [
          {
            required: true,
            message: '请输入安装数',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  mounted() {
    this.getCountry();
    this.advertiser();
    this.getList();
  },
  computed: {},
  methods: {
    // 国家
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    // 上游
    advertiser() {
      getAdvertiserList().then((res) => {
        this.options.advertiser = res.result;
      });
    },
    getList(types) {
      if (types == 'filter') {
        this.pages.pageNum = 1;
        this.isShowPage = false;
      }
      let query = Object.assign(this.filter, this.pages);
      this.loading.list = true;
      fetchEMUTaskPage(query).then((res) => {
        let data = res.result;
        this.list = [];
        this.list = data.records;
        this.pagesTotal = data.total;
        this.loading.list = false;
        this.isShowPage = true;
      });
    },
    // 编辑
    edit(row) {
      this.$set(this.taskEdit, 'id', row.id);
      this.$set(this.taskEdit, 'status', row.status);
      this.$set(this.taskEdit, 'price', row.price);
      this.$set(this.taskEdit, 'installCount', row.installCount);
      this.diallog.task = true;
    },
    taskEditSub(taskEdit) {
      this.$refs[taskEdit].validate((valid) => {
        if (!valid) return;
        this.loading.btn = true;
        handleEMUTaskEdit(this.taskEdit).then((res) => {
          console.log(res);
          this.diallog.task = false;
          this.loading.btn = false;
          this.getList();
        });
      });
    },
    // 删除
    del(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          fetchEMUTaskDel({
            id: id,
          }).then((res) => {
            console.log(res);
            this.getList();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.getList();
    },
  },
};
