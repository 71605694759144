import request from 'utils/request';
const api = {
  EMU_PAGE: '/api/admin/emu/page',
  EMU_LIST: '/api/admin/emu/list',
  EMU_DETAILS: '/api/admin/emu/get',
  EMU_UPDATE: '/api/admin/emu/update',
  EMU_ADD: '/api/admin/emu/add',
  EMU_DEL: '/api/admin/emu/delete',
  EMU_TASK_PAGE: '/api/admin/emu/task/page',
  EMU_TASK_UPDATE: '/api/admin/emu/task/update',
  EMU_TASK_DEL: '/api/admin/emu/task/delete',
};

// 分页
export function fetchEMUPage(query) {
  return request({
    url: api.EMU_PAGE,
    method: 'get',
    params: query,
  });
}

// 列表
export function fetchEMUList(query) {
  return request({
    url: api.EMU_LIST,
    method: 'get',
    params: query,
  });
}

// 详情
export function fetchEMUDetails(query) {
  return request({
    url: api.EMU_DETAILS,
    method: 'get',
    params: query,
  });
}

// 更新
export function handleEMUUpdate(data) {
  return request({
    url: api.EMU_UPDATE,
    method: 'post',
    data,
  });
}

// 添加
export function handleEMUAdd(data) {
  return request({
    url: api.EMU_ADD,
    method: 'post',
    data,
  });
}

// 删除
export function fetchEMUDel(query) {
  return request({
    url: api.EMU_DEL,
    method: 'get',
    params: query,
  });
}

// emu 任务分页
export function fetchEMUTaskPage(query) {
  return request({
    url: api.EMU_TASK_PAGE,
    method: 'get',
    params: query,
  });
}

// emu 任务更新
export function handleEMUTaskEdit(data) {
  return request({
    url: api.EMU_TASK_UPDATE,
    method: 'post',
    data,
  });
}

// emu 任务删除
export function fetchEMUTaskDel(query) {
  return request({
    url: api.EMU_TASK_DEL,
    method: 'get',
    params: query,
  });
}
